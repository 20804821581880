import React from 'react';
import './App.css';
import {getSession, toLogin} from "./session";
import {Session} from "./models";
import {Link} from "react-router-dom";
import {Reports} from "./Reports";

export function Home() {
  let session: Session = getSession();
  if (session?.session_id == null) {
    toLogin();
  }
  return (
    <div className="App">
      <header className="App-header">
        <div>
          {getSession()?.login_id}&nbsp;
          <Link to="/pass">Change Password</Link>&nbsp;
          <Link to="/logout">Logout</Link>&nbsp;
          { /*(getSession()?.capability & CAP_ADMIN) !== 0 ? <Link to="/users">Users</Link> : "" */}
        </div>
      </header>
      <div className="App-body">
        <Reports/>
      </div>
    </div>
  );
}

