import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import './App.css';
import {Login} from "./Login";
import {Logout} from "./Logout";
import {Home} from "./Home";
import {ChangePassword} from "./ChangePassword";
import {Users} from "./Users";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/logout" element={<Logout/>}/>
          <Route path="/pass" element={<ChangePassword/>}/>
          <Route path="/users" element={<Users/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
