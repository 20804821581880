import React, {useEffect, useState} from "react";
// @ts-ignore
import { useTable, Column } from "react-table";
import {download, getReport, getSession, toLogin} from "./session";
import {Table} from "./Table";

interface Data {
  qr_code: string,
  serial_no: string,
  iccid: string,
  pass1: string,
  pass2: string
}

const columns: Column<Data>[] = [
  { Header: "QR code", accessor: "qr_code" },
  { Header: "SERIAL", accessor: "serial_no" },
  { Header: "ICCID", accessor: "iccid" },
  { Header: "RSRP", accessor: "rsrp" },
  { Header: "Test 1", accessor: "pass1" },
  { Header: "Test 2", accessor: "pass2" },
];

export const Reports = () => {
  const [data, setData] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable<Data>({
    columns,
    data
  });
  useEffect(() => {
    getReport().then((res) => { setData(res.reports)}).catch(() => toLogin());
  }, []);
  const doDownload = () => {
    const session = getSession();
    if (session == null) {
      toLogin();
    } else {
      download("/api/report/download", {"session_id": session.session_id});
    }
  }
  return (
    <>
      <div>
        <button className="dl-button" onClick={doDownload}>download</button>
      </div>
      <p></p>
      <Table getTableProps={getTableProps}
             getTableBodyProps={getTableBodyProps}
             headerGroups={headerGroups}
             rows={rows}
             prepareRow={prepareRow}/>
    </>
  );
};
