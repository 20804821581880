import {Session} from "./models";

const SESSION_KEY = 'session';
let currentSession: Session | null = null;

export const loadSession = () : Session => {
  const s = sessionStorage.getItem(SESSION_KEY);
  return s ? JSON.parse(s) : {};
};

export const getSession = (): Session => {
  return currentSession || (currentSession = loadSession());
}

export const storeSession = (session: any) => {
  sessionStorage.setItem(SESSION_KEY, JSON.stringify(currentSession = session));
  return session;
};

export const login = async (login_id: string, password: string) => {
  const res = await fetch("/api/auth/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({login_id: login_id, password: password})
  });
  if (res?.ok) {
    return storeSession(await res.json());
  }
  return null;
};

const fetchFromServer = async (uri: string, data: any) => {
  const session = getSession();
  const res = await fetch(uri, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + session.session_id
    },
    body: JSON.stringify(data)
  });
  if (res?.ok) {
    if (res?.headers?.get('Content-Type')?.startsWith('application/json')) {
      return await res.json();
    }
    return await res.text();
  }
  throw new Error(res.status.toString());
}

const getFromServer = async (uri: string, data: any) => {
  const session = getSession();
  let query = new URLSearchParams(data).toString();
  if (query !== "") {
    query = "?" + query;
  }
  const res = await fetch(uri + query, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + session.session_id
    },
  });
  if (res?.ok) {
    if (res?.headers?.get('Content-Type')?.startsWith('application/json')) {
      return await res.json();
    }
    return await res.text();
  }
  throw new Error(res.status.toString());
};

export const download = (url: string, params: any) => {
  const form: HTMLFormElement = document.createElement('form');
  form.method = "POST";
  form.action = url;
  form.addEventListener('formdata', (e) => {
    const fd = e.formData;
    const keys = Object.keys(params);
    keys.forEach((key: string) => { fd.set(key, params[key]); });
  });
  document.body.append(form);
  form.submit();
  form.remove();
};


export const logout = async () => {
  const session = getSession();
  if (session?.session_id) {
    sessionStorage.removeItem(SESSION_KEY);
    return await fetchFromServer("/api/auth/logout", {});
  }
  return null;
};

export const changePassword = async (currentPassword: string, newPassword: string) => {
  return await fetchFromServer("/api/auth/password", {
    "old_password": currentPassword,
    "password": newPassword
  });
};

export const getReport = async () => {
  return await getFromServer("/api/report", {});
};

export const getUsers = async () => {
  return await fetchFromServer("/api/user/list", {});
};

export const toLogin = () => {
  window.location.replace("/login");
};

// eslint-disable-next-line no-extend-native
Date.prototype.toString = function dateToString() {
  return this.getFullYear()
    + '/' + ('0' + (this.getMonth() + 1)).slice(-2)
    + '/' + ('0' + this.getDate()).slice(-2)
    + ' ' + ('0' + this.getHours()).slice(-2)
    + ':' + ('0' + this.getMinutes()).slice(-2)
    + ':' + ('0' + this.getSeconds()).slice(-2);
};
