import React from 'react';
import {logout} from "./session";
import {Link} from "react-router-dom";

export const Logout = () => {
  logout().then(_ => {});
  return (
    <div className="Logout">
      <header className="App-header">
      </header>
      <body className="App-body">
        <p>Logoutしました</p>
        <Link to="/login">Login</Link>
      </body>
    </div>
  );
};
