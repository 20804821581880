import React, {useRef} from 'react';
import {login} from "./session";
import "./Login.css"

export const Login = () => {
  const loginIdRef = useRef<HTMLInputElement>(null!);
  const passwordRef = useRef<HTMLInputElement>(null!);
  const loginClicked = async (event: any) => {
    event.preventDefault();
    const loginId = loginIdRef.current.value;
    const password = passwordRef.current.value;
    try {
      const session = await login(loginId, password);
      if (session?.session_id != null) {
        window.location.replace("/");
      }
    } catch (e) {
      alert("Login failed.");
    }
  }
  return (
    <div className="Login">
      <div className="Login-content">
        <h3>Login</h3>
        <form onSubmit={loginClicked}>
          <div className="grid">
            <div className="label-item">User ID</div>
            <div className="grid-item"><input className="text" ref={loginIdRef}/></div>
            <div className="label-item">Password</div>
            <div className="grid-item"><input className="text" ref={passwordRef} type="password"/></div>
          </div>
          <div className="Login-right">
            <button type="submit" onSubmit={loginClicked}>login</button>
          </div>
        </form>
      </div>
    </div>
  );
};
