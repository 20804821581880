import React, {useRef} from 'react';
import {changePassword} from "./session";
import "./Login.css"

export const ChangePassword = () => {
  const currentPasswordRef = useRef<HTMLInputElement>(null!);
  const newPasswordRef = useRef<HTMLInputElement>(null!);
  const confirmPasswordRef = useRef<HTMLInputElement>(null!);
  const changePasswordClicked = async (event: any) => {
    event.preventDefault();
    const currentPassword = currentPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;
    if (newPassword !== confirmPassword) {
      alert("新パスワードが食い違っています");
      return;
    }
    try {
      await changePassword(currentPassword, newPassword);
      alert("更新しました。");
      window.location.replace("/");
    } catch (e) {
      alert("失敗しました: " + e);
    }
  }
  return (
    <div className="ChangePassword">
      <div className="Login-content">
        <h3>Change Password</h3>
        <form onSubmit={changePasswordClicked}>
          <div className="grid">
            <div className="label-item">Current Password</div>
            <div className="grid-item"><input className="text" ref={currentPasswordRef} type="password"/></div>
            <div className="label-item">New Password</div>
            <div className="grid-item"><input className="text" ref={newPasswordRef} type="password"/></div>
            <div className="label-item">Confirm New Password</div>
            <div className="grid-item"><input className="text" ref={confirmPasswordRef} type="password"/></div>
          </div>
          <div className="Login-right">
            <button type="submit" onSubmit={changePasswordClicked}>Update</button>
          </div>
        </form>
      </div>
    </div>
  );
};
