import React, {useEffect, useState} from "react";
// @ts-ignore
import { useTable, Column } from "react-table";
import {getUsers, toLogin} from "./session";
import {Table} from "./Table";

interface Data {
  uid: number,
  login_id: string,
  capability: string,
}

const columns: Column<Data>[] = [
  { Header: "uid", accessor: "uid" },
  { Header: "login_id", accessor: "login_id" },
  { Header: "capability", accessor: "capability" },
];

export const Users = () => {
  const [data, setData] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable<Data>({
    columns,
    data
  });
  useEffect(() => {
    getUsers().then((res) => setData(res.users)).catch(() => toLogin());
  }, []);
  return (<Table getTableProps={getTableProps}
                 getTableBodyProps={getTableBodyProps}
                 headerGroups={headerGroups}
                 rows={rows}
                 prepareRow={prepareRow}/>);
};
