import React from "react";

export interface TableProps {
  getTableProps: any,
  getTableBodyProps: any,
  headerGroups: any,
  rows: any,
  prepareRow: any
}

export const Table = (props: TableProps) => {
  return (
    <>
      <table {...props.getTableProps()}>
        <thead>
        {props.headerGroups.map((headerGroup: { getHeaderGroupProps: () => JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableRowElement> & React.HTMLAttributes<HTMLTableRowElement>; headers: any[]; }) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
        </thead>

        <tbody {...props.getTableBodyProps()}>
        {props.rows.map((row: { getRowProps: () =>
            JSX.IntrinsicAttributes &
            React.ClassAttributes<HTMLTableRowElement> &
            React.HTMLAttributes<HTMLTableRowElement>;
          cells: any[]; }, _: any) => {
          props.prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                )
              })}
            </tr>
          );
        })}
        </tbody>
      </table>
    </>
  );
};